.projectLine{
    display: flex;
    text-align: left;
    margin-bottom: 30px;
    font-size: 2.3rem;
}
.projectLine:last-child{
    margin-bottom: 33%;
}

#portfolio {
    min-height: 100vh;
}

#portfolio .projectTitle{
    font-size: 3rem;
}

#portfolio .social {
    margin: 24px 0;
    padding: 0;
    font-size: 30px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, .8);
}

#portfolio .social li {
    display: inline-block;
    margin: 0 15px;
    padding: 0;
}

#portfolio a { color: #fff; }
#portfolio a:hover { color: #11ABB0; }
@media only screen and (min-width: 767px) {
    .Grid{
        display: grid;
        grid-template-columns: repeat(13, 110px);
        margin: 0 auto 12px auto;
        justify-content: center;
    }

    .GridLeft{
        grid-column: 1 / 10;
    }

    .GridRight{
        grid-column: 10 / 14;
    }
}

@media only screen and (max-width: 767px) {
    .Grid{
        display: grid;
        margin: 0 auto 12px auto;
        justify-content: center;
    }

    .GridLeft{
        margin: 20px;
    }

    .GridRight{
        margin-top: -70px;
    }
}

#portfolio h1 span{
    border-bottom: 3px solid #11ABB0;
    padding-bottom: 6px;
}